import React, { useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import LogoAltelis from "../../../assets/icons/logo-altelis-v2.svg";
import "../Auth.scss";

const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [generalError, setGeneralError] = useState("");
    const { token } = useParams();
    const navigate = useNavigate();

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError(false);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setPasswordError(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setPasswordError(true);
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}users/settings/reset_password/${token}`,
                { user: { password } },
                { withCredentials: true }
            );
            if (response.status === 200) {
                alert("Mot de passe réinitialisé avec succès.");
                navigate("/login");
            }
        } catch (error) {
            console.error(
                "Erreur lors de la réinitialisation du mot de passe.",
                error
            );
            setGeneralError(
                "Erreur lors de la réinitialisation du mot de passe."
            );
        }
    };

    return (
        <div className="main-container">
            <div className="main-background" />
            <div className="main-content">
                <div className="form-container">
                    <div className="form-header">
                        <img
                            className="large"
                            src={LogoAltelis}
                            alt="Logo Altelis Monitor"
                        />
                        <h1>Réinitialiser mon mot de passe</h1>
                        <p>Veuillez entrer votre nouveau mot de passe.</p>
                    </div>
                    {generalError && (
                        <p className="error-message">{generalError}</p>
                    )}
                    <form className="form-body" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label className="form-label">
                                Nouveau mot de passe{" "}
                                <span className="required">*</span>
                            </label>
                            <input
                                type="password"
                                className={`form-input ${
                                    passwordError ? "form-input-error" : ""
                                }`}
                                value={password}
                                onChange={handlePasswordChange}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">
                                Confirmer le mot de passe{" "}
                                <span className="required">*</span>
                            </label>
                            <input
                                type="password"
                                className={`form-input ${
                                    passwordError ? "form-input-error" : ""
                                }`}
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                            />
                        </div>
                        <button className="btn monitor-button" type="submit">
                            <span>Réinitialiser le mot de passe</span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
