import React, { useState } from "react";
import CustomBigCard from "../custom/CustomBigCard";
import Reservations from "./Reservations";
import { get_meta_bookings } from "../../api";
import dayjs from "dayjs";
import { globalBookings } from "../admin/functionGlobal";
import { useSelector } from "react-redux";
import { selectEndDate, selectStartDate } from "../../reducers/filterSlice";
const Meta = ({ meta, period, property }) => {
    const [reservations, setReservations] = useState(false);
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const [data] = useState(meta);

    React.useEffect(() => {
        get_meta_bookings(
            property?.id,
            dayjs(startDate).format("YYYY-MM-DD"),
            dayjs(endDate).format("YYYY-MM-DD")
        )
            .then((response) => {
                setReservations(globalBookings(response));
            })
            .catch((error) => console.log("Erreur lors de l'appel API", error));
    }, [property?.id, startDate, endDate]);

    return data !== undefined && data !== null ? (
        <div className={`ads-container ${!data.sample ? "" : "sample"}`}>
            <div className="ads-row">
                <CustomBigCard
                    data_first={data.data[0]}
                    data_second={data.data[1]}
                    data_third={data.data[5]}
                    period={period}
                    tooltip={
                        data.data[0].tooltip ? data.data[0].tooltip : false
                    }
                />
                <CustomBigCard
                    data_first={data.data[4]}
                    data_second={data.data[2]}
                    data_third={data.data[3]}
                    period={period}
                    tooltip={
                        data.data[0].tooltip ? data.data[0].tooltip : false
                    }
                    isInvert
                />
            </div>
            <Reservations data={reservations} period={period} />
        </div>
    ) : (
        <div className="analytics-row is_big">
            <p>
                Vous ne disposez pas de compte Meta ou nous n'avons pas d'accès
                administrateur à ce compte.
                <br />
                Veuillez contacter notre support sur l'adresse{" "}
                <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="mailto: support@altelis.com"
                >
                    support@altelis.com
                </a>{" "}
                si vous souhaitez voir ces informations.
            </p>
        </div>
    );
};

export default Meta;
