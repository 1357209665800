import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import LogoAltelis from "../../../assets/icons/logo-altelis-v2.svg";
import MonitorButton from "../../../assets/icons/login.svg";
import "../Auth.scss";

const RequestReset = () => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [generalError, setGeneralError] = useState("");
    const [csrfToken, setCsrfToken] = useState("");

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_API_URL}csrf_token`,
                    { withCredentials: true }
                );
                setCsrfToken(response.data.csrf_token);
                console.log("CSRF Token fetched:", response.data.csrf_token);
            } catch (error) {
                console.error(
                    "Erreur lors de la récupération du token CSRF :",
                    error
                );
                setGeneralError(
                    "Erreur lors de la récupération du token CSRF."
                );
            }
        };

        fetchCsrfToken();
    }, []);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasError = false;

        if (!email) {
            setEmailError(true);
            hasError = true;
        }

        if (hasError) {
            return;
        }

        try {
            if (!csrfToken) {
                throw new Error("CSRF Token non trouvé.");
            }

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}users/settings/reset_password`,
                { user: { email } },
                {
                    headers: {
                        "X-CSRF-Token": csrfToken,
                    },
                    withCredentials: true,
                }
            );
            console.log(response);
            if (response.status === 200) {
                alert("Un email de réinitialisation a été envoyé.");
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setEmailError(true);
                setGeneralError("L'adresse e-mail n'existe pas.");
            } else {
                console.error(
                    "Erreur lors de la demande de réinitialisation du mot de passe.",
                    error
                );
                setGeneralError(
                    "Erreur lors de la demande de réinitialisation du mot de passe."
                );
            }
        }
    };

    return (
        <div className="main-container">
            <div className="main-background" />
            <div className="main-content">
                <div className="form-container">
                    <div className="form-header">
                        <img
                            className="large"
                            src={LogoAltelis}
                            alt="Logo Altelis Monitor"
                        />
                        <h1>Réinitialiser mon mot de passe</h1>
                        <p>
                            Veuillez entrer votre adresse e-mail. Vous recevrez
                            des instructions pour réinitialiser votre mot de
                            passe.
                        </p>
                    </div>
                    {generalError && (
                        <p className="error-message">{generalError}</p>
                    )}
                    <form className="form-body" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label className="form-label">
                                Email <span className="required">*</span>
                            </label>
                            <input
                                type="email"
                                className={`form-input ${
                                    emailError ? "form-input-error" : ""
                                }`}
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </div>
                        <button className="btn monitor-button" type="submit">
                            <span>Envoyer le lien</span>
                            <img src={MonitorButton} alt="Monitor Button" />
                        </button>
                    </form>
                    <hr className="hr-text" data-content="OU" />
                    <Link
                        to="/login"
                        className="forgot-password"
                        style={{ margin: "0 auto" }}
                    >
                        Retour à la connexion
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default RequestReset;
