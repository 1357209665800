import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userSlice";
import filterReducer from "./reducers/filterSlice";

const root = createRoot(document.getElementById("root"));
const store = configureStore({
    reducer: {
        user: userReducer,
        filters: filterReducer,
    },
});

root.render(
    <Provider store={store}>
        <React.StrictMode>
            <BrowserRouter>
                <ToastContainer />
                <App />
            </BrowserRouter>
        </React.StrictMode>
    </Provider>
);
