import { CircularProgress } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import dayjs from "dayjs";
import localeFr from "dayjs/locale/fr";
import duration from "dayjs/plugin/duration";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { current_user } from "./api";
import AdminPanel from "./components/admin/AdminPanel";

import Login from "./pages/auth/login/Login";
import Registration from "./pages/auth/registration/Registration";
import RequestReset from "./pages/auth/reset/RequestReset";
import ResetPassword from "./pages/auth/reset/ResetPassword";

import PrivateRoute from "./components/routes/PrivateRoute";
import { setInitial } from "./reducers/filterSlice";
import { setUser } from "./reducers/userSlice";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./assets/styles/App.scss";

dayjs.locale(localeFr);
dayjs.extend(duration);

const App = () => {
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        setLoaded(false);
        current_user()
            .then((response) => {
                if (!response.request.responseURL.includes("log_in")) {
                    const user = response.data;
                    dispatch(
                        setUser({
                            id: user.id,
                            email: user.email,
                            name: user.name,
                            firstname: user.firstname,
                            role: user.role,
                            properties: user.properties,
                        })
                    );
                    dispatch(setInitial());
                    setLoaded(true);
                    if (user.properties.length === 1) {
                        navigate("/details");
                    } else {
                        navigate("/admin");
                    }
                } else {
                    setLoaded(true);
                }
            })
            .catch(() => {
                setLoaded(true);
                navigate("/login");
            });
    }, []);

    return loaded ? (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <Routes>
                <Route
                    path="admin/*"
                    element={
                        <PrivateRoute>
                            <AdminPanel />
                        </PrivateRoute>
                    }
                />
                <Route path="details" element={<AdminPanel />} />
                <Route exact path="login" element={<Login />} />
                <Route path="registration/new" element={<Registration />} />
                <Route exact path="reset" element={<RequestReset />} />
                <Route path="reset/:token" element={<ResetPassword />} />
                <Route path="*" element={<Navigate to="admin" />} />
            </Routes>
        </GoogleOAuthProvider>
    ) : (
        <div className="loader-container">
            <CircularProgress size={30} />
        </div>
    );
};

export default App;
