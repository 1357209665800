import React, { useEffect, useReducer, useCallback, useMemo } from "react";
import LogoAltelis from "../../../assets/icons/logo-altelis-v2.svg";
import MonitorButton from "../../../assets/icons/login.svg";
import GoogleButton from "../../../assets/images/google-logo.png";
import IlluLogin from "../../../assets/images/illu-login.gif";
import { useNavigate, Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUser } from "../../../reducers/userSlice";
import axios from "axios";
import "../Auth.scss";

const initialState = {
    email: "",
    password: "",
    error: "",
    loader: false,
    redirectToReferrer: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_EMAIL":
            return { ...state, email: action.payload, error: "" };
        case "SET_PASSWORD":
            return { ...state, password: action.payload, error: "" };
        case "SET_ERROR":
            return { ...state, error: action.payload };
        case "SET_LOADER":
            return { ...state, loader: action.payload };
        case "REDIRECT":
            return { ...state, redirectToReferrer: true };
        default:
            return state;
    }
};

const Login = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { email, password, error, loader, redirectToReferrer } = state;
    const user = useSelector(selectUser);
    const navigate = useNavigate();

    useEffect(() => {
        if (redirectToReferrer) {
            user.role !== "admin"
                ? navigate(`/admin/monitor/details/${user.client_id_api}`)
                : navigate("/admin/details");
        }
    }, [redirectToReferrer, user, navigate]);

    const handleSubmit = useCallback(
        (event) => {
            if (event.type === "click" || event.key === "Enter") {
                dispatch({ type: "SET_LOADER", payload: true });
                axios
                    .post(
                        process.env.REACT_APP_BACKEND_API_URL +
                            "accounts/log_in/",
                        { email, password },
                        { withCredentials: true }
                    )
                    .then(() => {
                        navigate(0);
                    })
                    .catch((err) => {
                        dispatch({
                            type: "SET_ERROR",
                            payload:
                                "Identifiants incorrects, veuillez réessayer.",
                        });
                        dispatch({ type: "SET_LOADER", payload: false });
                        console.log(err);
                    });
            }
        },
        [email, password, navigate]
    );

    const isEmailValid = useMemo(() => /\S+@\S+\.\S+/.test(email), [email]);

    const handleGoogleLogin = () => {
        window.location.href = `${process.env.REACT_APP_BACKEND_URL}auth/google`;
    };

    return (
        <div className="main-container">
            <div className="main-background" />
            <div className="main-content-grid">
                <div className="main-content-grid-illustration">
                    <img src={IlluLogin} alt="Illustration page login" />
                    <p>
                        Boostez votre hôtel avec des statistiques
                        incontournables Direct, Livie, Google Ads, Métas...
                    </p>
                </div>
                <div className="main-content-grid-form">
                    <div className="form-container">
                        <div className="form-header">
                            <img
                                className="large"
                                src={LogoAltelis}
                                alt="Logo Altelis Monitor"
                            />
                            <h1>Bienvenue sur Altelis Monitor 👋</h1>
                            <p>
                                Veuillez saisir vos identifiants pour vous
                                connecter
                            </p>
                        </div>
                        {error && <p className="error-message">{error}</p>}
                        <form
                            className="form-body"
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <div className="form-group">
                                <label className="form-label">
                                    Email <span className="required"> *</span>
                                </label>
                                <input
                                    type="email"
                                    placeholder="Saisissez votre adresse email"
                                    value={email}
                                    onChange={(e) =>
                                        dispatch({
                                            type: "SET_EMAIL",
                                            payload: e.target.value,
                                        })
                                    }
                                    onKeyUp={(e) => handleSubmit(e)}
                                    className={`form-input ${
                                        email && !isEmailValid
                                            ? "form-input-error"
                                            : ""
                                    }`}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Mot de passe{" "}
                                    <span className="required"> *</span>
                                </label>
                                <input
                                    className="form-input"
                                    type="password"
                                    placeholder="Saisissez votre mot de passe"
                                    value={password}
                                    onChange={(e) =>
                                        dispatch({
                                            type: "SET_PASSWORD",
                                            payload: e.target.value,
                                        })
                                    }
                                    onKeyUp={(e) => handleSubmit(e)}
                                />
                            </div>
                        </form>

                        <Link
                            to={`${process.env.REACT_APP_BACKEND_URL}users/settings/reset_password`}
                            className="forgot-password"
                            target="_blank"
                        >
                            Mot de passe oublié ?
                        </Link>
                        <div className="form-button-container">
                            {loader ? (
                                <button className="btn-loading" disabled>
                                    <CircularProgress
                                        size={20}
                                        className="white-loader"
                                    />
                                </button>
                            ) : (
                                <button
                                    className="btn monitor-button"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    <span>Se connecter</span>
                                    <img
                                        src={MonitorButton}
                                        alt={MonitorButton}
                                    />
                                </button>
                            )}
                            <hr className="hr-text" data-content="OU" />
                            <button
                                className="btn google-button"
                                onClick={handleGoogleLogin}
                            >
                                <span>Se connecter avec Google</span>
                                <img
                                    src={GoogleButton}
                                    alt="Google Login Button"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
