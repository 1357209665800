// import sampleMeta from "./sample/meta.json";
// import sampleAds from "./sample/ads.json";
import sampleBookings from "./sample/reservations.json";
import dayjs from "dayjs";

const regionNames = new Intl.DisplayNames(["fr"], { type: "region" });

const channel = (val) => {
    switch (val) {
        case "Metasearch_googlehpa":
            return "Google";
        case "Metasearch_trivago":
            return "Trivago";
        case "Metasearch_bing":
            return "Bing";
        case "Metasearch_tripadvisor":
            return "Tripadvisor";
        default:
            return val || "Undefined";
    }
};

export function globalAds(ads) {
    if (!ads || !Array.isArray(ads) || ads.length === 0) {
        return { data: [] };
    }

    let arr = {
        data: [
            {
                label: "Montant des réservations",
                attribut: "€",
                data_0: ads[0]?.data?.allConversionsValue || 0,
                data_1: ads[1]?.data?.allConversionsValue || 0,
                data_2: ads[2]?.data?.allConversionsValue || 0,
                data_3: ads[3]?.data?.allConversionsValue || 0,
                tooltip:
                    "Les montants des réservations sont la somme des réservations obtenues sur la période avec ads.",
            },
            {
                label: "Réservations",
                isNotRound: true,
                data_0: ads[0]?.data?.conversions || 0,
                data_1: ads[1]?.data?.conversions || 0,
                data_2: ads[2]?.data?.conversions || 0,
                data_3: ads[3]?.data?.conversions || 0,
                tooltip:
                    "Le coût correspond à la somme de vos dépenses au cours de cette période.",
            },
            {
                label: "Equiv. commission",
                attribut: "%",
                decimals: 2,
                data_0: (ads[0]?.data?.earnings_percent || 0) * 100,
                data_1: (ads[1]?.data?.earnings_percent || 0) * 100,
                data_2: (ads[2]?.data?.earnings_percent || 0) * 100,
                data_3: (ads[3]?.data?.earnings_percent || 0) * 100,
            },
            {
                label: "ROI",
                decimals: 2,
                data_0: ads[0]?.data?.roi || 0,
                data_1: ads[1]?.data?.roi || 0,
                data_2: ads[2]?.data?.roi || 0,
                data_3: ads[3]?.data?.roi || 0,
            },
            {
                label: "Coût",
                attribut: "€",
                data_0: ads[0]?.data?.costMicros || 0,
                data_1: ads[1]?.data?.costMicros || 0,
                data_2: ads[2]?.data?.costMicros || 0,
                data_3: ads[3]?.data?.costMicros || 0,
            },
            {
                label: "Panier moyen",
                attribut: "€",
                data_0: ads[0]?.data?.conversionsValue
                    ? ads[0].data.conversionsValue / ads[0].data.conversions
                    : 0,
                data_1: ads[1]?.data?.conversionsValue
                    ? ads[1].data.conversionsValue / ads[1].data.conversions
                    : 0,
                data_2: ads[2]?.data?.conversionsValue
                    ? ads[2].data.conversionsValue / ads[2].data.conversions
                    : 0,
                data_3: ads[3]?.data?.conversionsValue
                    ? ads[3].data.conversionsValue / ads[3].data.conversions
                    : 0,
            },
            {
                label: "Appels",
                data_0: ads[0]?.data?.phoneCalls || 0,
                data_1: ads[1]?.data?.phoneCalls || 0,
                data_2: ads[2]?.data?.phoneCalls || 0,
                data_3: ads[3]?.data?.phoneCalls || 0,
                isNotRound: true,
                tooltip:
                    "Les annonces Appel sont conçues pour encourager les utilisateurs à contacter votre entreprise par téléphone. Elles ne peuvent être diffusées que sur les appareils qui permettent de passer des appels. Lorsqu'un client potentiel clique sur votre annonce, l'annonce déclenche un appel vers votre établissement depuis son appareil.",
            },
            {
                label: "Impressions",
                data_0: ads[0]?.data?.impressions || 0,
                data_1: ads[1]?.data?.impressions || 0,
                data_2: ads[2]?.data?.impressions || 0,
                data_3: ads[3]?.data?.impressions || 0,
                isNotRound: true,
                tooltip:
                    "Les impressions vous permettent de déterminer combien de fois votre annonce a été vue.",
            },
            {
                label: "Clics",
                data_0: ads[0]?.data?.clicks || 0,
                data_1: ads[1]?.data?.clicks || 0,
                data_2: ads[2]?.data?.clicks || 0,
                data_3: ads[3]?.data?.clicks || 0,
                isNotRound: true,
                tooltip:
                    "Il s'agit du nombre de fois où les internautes ont cliqué sur votre annonce.",
            },
            {
                label: "CTR",
                attribut: "%",
                data_0: `${(ads[0]?.data?.ctr || 0) * 100}`,
                data_1: `${(ads[1]?.data?.ctr || 0) * 100}`,
                data_2: `${(ads[2]?.data?.ctr || 0) * 100}`,
                data_3: `${(ads[3]?.data?.ctr || 0) * 100}`,
                tooltip:
                    "Le taux de clics (CTR) détermine la fréquence à laquelle les utilisateurs cliquent sur votre annonce lorsqu'ils la voient et vous permet d'évaluer son efficacité.",
            },
            {
                label: "Cpc moy.",
                attribut: "€",
                data_0: ads[0]?.data?.average_cpc || 0,
                data_1: ads[1]?.data?.average_cpc || 0,
                data_2: ads[2]?.data?.average_cpc || 0,
                data_3: ads[3]?.data?.average_cpc || 0,
                tooltip:
                    "Le coût par clic (CPC) moyen correspond au montant que vous avez payé pour votre annonce, divisé par le nombre total de clics qu'elle a générés.",
            },
            {
                label: "Taux de conv.",
                attribut: "%",
                data_0: `${(ads[0]?.data?.conversion_rate || 0) * 100}`,
                data_1: `${(ads[1]?.data?.conversion_rate || 0) * 100}`,
                data_2: `${(ads[2]?.data?.conversion_rate || 0) * 100}`,
                data_3: `${(ads[3]?.data?.conversion_rate || 0) * 100}`,
                tooltip:
                    "La statistique relative au taux de conversion ('Taux de conv.') correspond à la fréquence moyenne à laquelle une interaction avec une annonce génère une conversion. Il s'agit des données 'Conversions' divisées par le nombre d'interactions avec votre annonce.",
            },
        ],
    };

    return arr;
}

export function globalMeta(meta, metaCost) {
    if (
        !meta ||
        !Array.isArray(meta) ||
        meta.length === 0 ||
        !metaCost ||
        !metaCost.datesRange
    ) {
        return {
            data: [],
            table_1: { data_0: [] },
            table_2: { data_0: [] },
        };
    }

    let arr = {
        data: [
            {
                label: "Montant des réservations",
                attribut: "€",
                data_0: meta[0]?.data?.all?.total_amount_booking || 0,
                data_1: meta[1]?.data?.all?.total_amount_booking || 0,
                data_2: meta[2]?.data?.all?.total_amount_booking || 0,
                data_3: meta[3]?.data?.all?.total_amount_booking || 0,
                tooltip:
                    "Les montants des réservations sont la somme des réservations obtenues sur la période avec meta.",
            },
            {
                label: "Réservations",
                isNotRound: true,
                data_0: meta[0]?.data?.all?.count_booking || 0,
                data_1: meta[1]?.data?.all?.count_booking || 0,
                data_2: meta[2]?.data?.all?.count_booking || 0,
                data_3: meta[3]?.data?.all?.count_booking || 0,
            },
            {
                label: "Equiv. commission",
                attribut: "%",
                decimals: 2,
                data_0:
                    meta[0]?.data?.all && metaCost.datesRange[0]?.cost
                        ? (1 /
                              (meta[0].data.all.total_amount_booking /
                                  metaCost.datesRange[0].cost)) *
                          100
                        : 0,
                data_1:
                    meta[1]?.data?.all && metaCost.datesRange[1]?.cost
                        ? (1 /
                              (meta[1].data.all.total_amount_booking /
                                  metaCost.datesRange[1].cost)) *
                          100
                        : 0,
                data_2:
                    meta[2]?.data?.all && metaCost.datesRange[2]?.cost
                        ? (1 /
                              (meta[2].data.all.total_amount_booking /
                                  metaCost.datesRange[2].cost)) *
                          100
                        : 0,
                data_3:
                    meta[3]?.data?.all && metaCost.datesRange[3]?.cost
                        ? (1 /
                              (meta[3].data.all.total_amount_booking /
                                  metaCost.datesRange[3].cost)) *
                          100
                        : 0,
            },
            {
                label: "ROI",
                decimals: 2,
                data_0:
                    meta[0]?.data?.all && metaCost.datesRange[0]?.cost
                        ? meta[0].data.all.total_amount_booking /
                          metaCost.datesRange[0].cost
                        : 0,
                data_1:
                    meta[1]?.data?.all && metaCost.datesRange[1]?.cost
                        ? meta[1].data.all.total_amount_booking /
                          metaCost.datesRange[1].cost
                        : 0,
                data_2:
                    meta[2]?.data?.all && metaCost.datesRange[2]?.cost
                        ? meta[2].data.all.total_amount_booking /
                          metaCost.datesRange[2].cost
                        : 0,
                data_3:
                    meta[3]?.data?.all && metaCost.datesRange[3]?.cost
                        ? meta[3].data.all.total_amount_booking /
                          metaCost.datesRange[3].cost
                        : 0,
            },
            {
                label: "Coût",
                attribut: "€",
                data_0: metaCost.datesRange[0]?.cost || 0,
                data_1: metaCost.datesRange[1]?.cost || 0,
                data_2: metaCost.datesRange[2]?.cost || 0,
                data_3: metaCost.datesRange[3]?.cost || 0,
                tooltip:
                    "Le coût correspond à la somme de vos dépenses au cours de cette période.",
            },
            {
                label: "Panier moyen",
                attribut: "€",
                data_0: meta[0]?.data?.all?.avg_basket || 0,
                data_1: meta[1]?.data?.all?.avg_basket || 0,
                data_2: meta[2]?.data?.all?.avg_basket || 0,
                data_3: meta[3]?.data?.all?.avg_basket || 0,
            },
        ],
        table_1: {
            data_0:
                meta[0]?.data?.by_country
                    ?.map((item) => ({
                        country: regionNames.of(item.country),
                        montant_resa: parseFloat(
                            item.total_amount_booking
                        ).toFixed(0),
                    }))
                    .slice(0, 5) || [],
        },
        table_2: {
            data_0:
                meta[0]?.data?.by_channel
                    ?.map((item) => ({
                        channel: channel(item.channel_code),
                        montant_resa: parseFloat(
                            item.total_amount_booking
                        ).toFixed(0),
                    }))
                    .slice(0, 5) || [],
        },
    };

    return arr;
}

export function globalBookings(bookings) {
    var arr = {
        data_0: [],
    };

    if (
        bookings !== null &&
        bookings !== undefined &&
        bookings.length !== 0 &&
        bookings.length !== undefined
    ) {
        bookings.forEach((item) => {
            arr[`data_0`].push({
                reference: item.reference,
                date_arrivee: dayjs(new Date(item.checkInDate)).format(
                    "DD/MM/YYYY"
                ),
                date_depart: dayjs(new Date(item.checkOutDate)).format(
                    "DD/MM/YYYY"
                ),
                montant_resa: item.totalAmount,
            });
        });
    } else {
        arr = sampleBookings;
    }

    return arr;
}

export function globalAll(data) {
    return [
        {
            label: "Montant total des réservations",
            attribut: "€",
            data_0:
                data.meta[0].data.all_no_filter !== null
                    ? data.meta[0].data.all_no_filter.total_amount_booking
                    : 0,
            data_1:
                data.meta[1].data.all_no_filter !== null
                    ? data.meta[1].data.all_no_filter.total_amount_booking
                    : 0,
            data_2:
                data.meta[2].data.all_no_filter !== null
                    ? data.meta[2].data.all_no_filter.total_amount_booking
                    : 0,
            data_3:
                data.meta[3].data.all_no_filter !== null
                    ? data.meta[3].data.all_no_filter.total_amount_booking
                    : 0,
        },
        {
            label: "Réservations Organiques",
            attribut: "€",
            data_0:
                data.meta[0].data.all_no_filter !== null &&
                data.meta[0].data.all !== null
                    ? data.meta[0].data.all_no_filter.total_amount_booking -
                      (data.ads[0].data.allConversionsValue +
                          data.meta[0].data.all.total_amount_booking)
                    : NaN,
            data_1:
                data.meta[1].data.all_no_filter !== null &&
                data.meta[1].data.all !== null
                    ? data.meta[1].data.all_no_filter.total_amount_booking -
                      (data.ads[1].data.allConversionsValue +
                          data.meta[1].data.all.total_amount_booking)
                    : NaN,
            data_2:
                data.meta[2].data.all_no_filter !== null &&
                data.meta[2].data.all !== null
                    ? data.meta[2].data.all_no_filter.total_amount_booking -
                      (data.ads[2].data.allConversionsValue +
                          data.meta[2].data.all.total_amount_booking)
                    : NaN,
            data_3:
                data.meta[3].data.all_no_filter !== null &&
                data.meta[3].data.all !== null
                    ? data.meta[3].data.all_no_filter.total_amount_booking -
                      (data.ads[3].data.allConversionsValue +
                          data.meta[3].data.all.total_amount_booking)
                    : NaN,
        },
        {
            label: "Réservations Google Ads",
            attribut: "€",
            data_0:
                data.ads[0].data.allConversionsValue !== null
                    ? data.ads[0].data.allConversionsValue
                    : 0,
            data_1:
                data.ads[1].data.allConversionsValue !== null
                    ? data.ads[1].data.allConversionsValue
                    : 0,
            data_2:
                data.ads[2].data.allConversionsValue !== null
                    ? data.ads[2].data.allConversionsValue
                    : 0,
            data_3:
                data.ads[3].data.allConversionsValue !== null
                    ? data.ads[3].data.allConversionsValue
                    : 0,
        },
        {
            label: "Réservations Métas",
            attribut: "€",
            data_0:
                data.meta[0].data.all !== null
                    ? data.meta[0].data.all.total_amount_booking
                    : NaN,
            data_1:
                data.meta[1].data.all !== null
                    ? data.meta[1].data.all.total_amount_booking
                    : NaN,
            data_2:
                data.meta[2].data.all !== null
                    ? data.meta[2].data.all.total_amount_booking
                    : NaN,
            data_3:
                data.meta[3].data.all !== null
                    ? data.meta[3].data.all.total_amount_booking
                    : NaN,
        },
    ];
}

export function macroAnalytics(data) {
    return {
        label: "Utilisateurs",
        decimals: 0,
        data_0: data[1]["totals"] ? data[1]["totals"][0] : 0,
        data_1: data[3]["totals"] ? data[3]["totals"][0] : 0,
        data_2: data[5]["totals"] ? data[5]["totals"][0] : 0,
        data_3: data[7]["totals"] ? data[7]["totals"][0] : 0,
        tooltip:
            "Utilisateurs qui ont initié au moins une session dans la plage de dates sélectionnée.",
        isNotRound: true,
    };
}

export function macroAds(ads) {
    return {
        data: [
            {
                label: "Montant des réservations",
                decimals: 0,
                attribut: "€",
                data_0: ads[0].data.allConversionsValue,
                data_1: ads[1].data.allConversionsValue,
                data_2: ads[2].data.allConversionsValue,
                data_3: ads[3].data.allConversionsValue,
                tooltip:
                    "Les montants des réservations sont la somme des réservations obtenues sur la période avec ads.",
            },
            {
                label: "ROI",
                decimals: 2,
                data_0: ads[0].data.roi,
                data_1: ads[1].data.roi,
                data_2: ads[2].data.roi,
                data_3: ads[3].data.roi,
            },
            {
                label: "Appels",
                decimals: 0,
                data_0: ads[0].data.phoneCalls,
                data_1: ads[1].data.phoneCalls,
                data_2: ads[2].data.phoneCalls,
                data_3: ads[3].data.phoneCalls,
                isNotRound: true,
                tooltip:
                    "Les annonces Appel sont conçues pour encourager les utilisateurs à contacter votre entreprise par téléphone. Elles ne peuvent être diffusées que sur les appareils qui permettent de passer des appels. Lorsqu'un client potentiel clique sur votre annonce, l'annonce déclenche un appel vers votre établissement depuis son appareil.",
            },
        ],
    };
}

export function macroMeta(meta, metaCost) {
    return {
        data: [
            {
                label: "Montant des réservations",
                attribut: "€",
                decimals: 0,
                data_0:
                    meta[0].data.all !== null
                        ? meta[0].data.all.total_amount_booking
                        : 0,
                data_1:
                    meta[1].data.all !== null
                        ? meta[1].data.all.total_amount_booking
                        : 0,
                data_2:
                    meta[2].data.all !== null
                        ? meta[2].data.all.total_amount_booking
                        : 0,
                data_3:
                    meta[3].data.all !== null
                        ? meta[3].data.all.total_amount_booking
                        : 0,
                tooltip:
                    "Les montants des réservations sont la somme des réservations obtenues sur la période avec meta.",
            },
            {
                label: "ROI",
                decimals: 2,
                data_0:
                    !metaCost["errors"] !== false &&
                    meta[0].data.all !== null &&
                    metaCost.datesRange[0].cost !== null
                        ? meta[0].data.all.total_amount_booking /
                          metaCost.datesRange[0].cost
                        : 0,
                data_1:
                    !metaCost["errors"] !== false &&
                    meta[1].data.all !== null &&
                    metaCost.datesRange[1].cost !== null
                        ? meta[1].data.all.total_amount_booking /
                          metaCost.datesRange[1].cost
                        : 0,
                data_2:
                    !metaCost["errors"] !== false &&
                    meta[2].data.all !== null &&
                    metaCost.datesRange[2].cost !== null
                        ? meta[2].data.all.total_amount_booking /
                          metaCost.datesRange[2].cost
                        : 0,
                data_3:
                    !metaCost["errors"] !== false &&
                    meta[2].data.all !== null &&
                    metaCost.datesRange[2].cost !== null
                        ? meta[2].data.all.total_amount_booking /
                          metaCost.datesRange[2].cost
                        : 0,
            },
        ],
    };
}
