import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        user: null,
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        unsetUser: (state) => {
            state.user = null;
        },
    },
});

export const { setUser, unsetUser } = userSlice.actions;
export const selectUser = (state) => state.user.user;
export default userSlice.reducer;
