import React, { useMemo } from "react";
import CustomList from "../custom/CustomList";
import classnames from "classnames";

const Reservations = ({ data }) => {

    const columns = useMemo(() => [
        {
            Header: 'Référence',
            accessor: 'reference',
            icon: 'role'
        },
        {
            Header: "Date d'arrivée",
            accessor: 'date_arrivee',
            icon: 'calendar',
            Cell: ({ cell: { value } }) => value,
        },
        {
            Header: "Date de départ",
            accessor: 'date_depart',
            icon: 'calendar',
            Cell: ({ cell: { value } }) => value,
        },
        {
            Header: 'Montant de Réservations',
            accessor: 'montant_resa',
            class: "border-item",
            attribut: "€",
            icon: 'euro',
            Cell: ({ cell: { value } }) => <span>{value.toFixed(2)}</span>
        }
    ], []);

    const containerClasses = classnames('transactions-container input-orders-container', {
        sample: data?.sample
    });

    return (
        <div className={containerClasses}>
            {data ? (
                <CustomList
                    entity={data["data_0"]}
                    columns={columns}
                    isFetchable={false}
                    isSelectable={false}
                    isPaginable={true}
                    pageSizeOptions={[5, 10]}
                    isSmall={true}
                />
            ) : null}
        </div>
    );
}

export default React.memo(Reservations);