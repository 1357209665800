import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const CustomDoughnut = ({ label, data, period }) => {
    const chartData = {
        labels: ['Organiques', 'Ads', 'Métas'],
        datasets: [
            {
                data: [data[1][period], data[2][period], data[3][period]],
                backgroundColor: ['#3B61E1', '#E7A85A', '#F4709F'],
                borderWidth: 0
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display: true,
                align: 'center',
                color: '#fff',
                font: {
                    size: 14,
                    weight: 600
                },
                formatter: (value) => Math.round((value / data[0][period]) * 100) + '%',
            },
        },
        cutout: 70
    };

    return (
        <div className='custom-doughnut-container'>
            {label && <span className='doughnut-title'>{label}</span>}
            <Doughnut data={chartData} width={100} options={options} />
        </div>
    );
}

export default CustomDoughnut;