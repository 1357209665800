import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import MonitorButton from "../../../assets/icons/login.svg";
import "../Auth.scss";

const Registration = () => {
    const [loading, setLoading] = useState(true);
    const [invitationValid, setInvitationValid] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [firstName, setFirstname] = useState("");
    const [csrfToken, setCsrfToken] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [firstNameError, setFirstnameError] = useState(false);
    const [generalError, setGeneralError] = useState("");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("invitation_token");
    const propertyId = queryParams.get("property_id");
    const navigate = useNavigate();

    useEffect(() => {
        const recuperationToken = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_API_URL}csrf_token`,
                    { withCredentials: true }
                );
                console.log("Log CSRF Token:", response);
                setCsrfToken(response.data.csrf_token);
                await checkInvitation(response.data.csrf_token);
            } catch (error) {
                console.error(
                    "Erreur lors de la récupération du token :",
                    error
                );
            }
        };

        const checkInvitation = async (csrfToken) => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_API_URL}validate_invitation`,
                    { token, property_id: propertyId },
                    {
                        headers: {
                            "X-CSRF-Token": csrfToken,
                        },
                        withCredentials: true,
                    }
                );
                console.log("Response from validate_invitation:", response);
                if (response.data.valid) {
                    setInvitationValid(true);
                } else {
                    setInvitationValid(false);
                    setGeneralError(
                        "Lien d'invitation expiré ou déjà utilisé."
                    );
                    navigate("/login");
                }
            } catch (error) {
                console.error("Erreur invitation :", error);
                setInvitationValid(false);
                setGeneralError(
                    "Erreur lors de la validation de l'invitation."
                );
            } finally {
                setLoading(false);
            }
        };

        recuperationToken();

        recuperationToken();
    }, [token, propertyId]);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError(false);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError(false);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
        setNameError(false);
    };

    const handleFirstnameChange = (e) => {
        setFirstname(e.target.value);
        setFirstnameError(false);
    };

    const submitMonitoRegister = async (e) => {
        e.preventDefault();
        let hasError = false;

        if (!email) {
            setEmailError(true);
            hasError = true;
        }

        if (!password) {
            setPasswordError(true);
            hasError = true;
        }

        if (!name) {
            setNameError(true);
            hasError = true;
        }

        if (!firstName) {
            setFirstnameError(true);
            hasError = true;
        }

        if (hasError) {
            return;
        }

        try {
            if (!csrfToken) {
                throw new Error("CSRF Token non trouvé.");
            }

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}users/register/invitation`,
                {
                    user: {
                        email,
                        password,
                        name,
                        firstname: firstName,
                        property_id: propertyId,
                    },
                    invitation: {
                        token: token,
                    },
                },
                {
                    headers: {
                        "X-CSRF-Token": csrfToken,
                    },
                    withCredentials: true,
                }
            );
            if (response.status === 200) {
                navigate("/admin");
            }
        } catch (error) {
            console.error("Erreur lors de la création du compte", error);
            setGeneralError("Erreur lors de la création du compte");
        }
    };

    const submitGoogleRegister = async (response) => {
        try {
            const r = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}auth/google/callback`,
                {
                    token: response.credential,
                    property_id: propertyId,
                    invitation_token: token,
                },
                {
                    headers: {
                        "X-CSRF-Token": csrfToken,
                    },
                    withCredentials: true,
                }
            );
            if (r.status === 200) {
                navigate("/admin");
            }
        } catch (error) {
            console.error("Erreur lors de la création du compte Google", error);
            setGeneralError(
                "Erreur lors de la création du compte Google, veuillez réessayer."
            );
        }
    };

    if (loading) {
        return (
            <div className="loader-container">
                <CircularProgress size={30} />
            </div>
        );
    }

    return (
        <div className="main-container">
            <div className="main-background" />
            <div className="main-content">
                <div className="form-container">
                    <div className="form-header">
                        <h1>Création d'un compte</h1>
                        <p>Choisissez entre un compte Monitor ou Google</p>
                    </div>
                    <div className="google-wrapper">
                        <GoogleLogin
                            className="google-button"
                            onSuccess={submitGoogleRegister}
                            onError={submitGoogleRegister}
                        />
                    </div>
                    <hr className="hr-text" data-content="OU" />
                    {generalError && (
                        <p className="error-message">{generalError}</p>
                    )}
                    <form className="form-body" onSubmit={submitMonitoRegister}>
                        <div className="form-group-container">
                            <div className="form-group">
                                <label className="form-label">
                                    Prénom <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    className={`form-input ${
                                        firstNameError ? "form-input-error" : ""
                                    }`}
                                    value={firstName}
                                    onChange={handleFirstnameChange}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Nom <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    className={`form-input ${
                                        nameError ? "form-input-error" : ""
                                    }`}
                                    value={name}
                                    onChange={handleNameChange}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label">
                                Email <span className="required">*</span>
                            </label>
                            <input
                                type="email"
                                className={`form-input ${
                                    emailError ? "form-input-error" : ""
                                }`}
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </div>
                        <div className="form-group form-margin">
                            <label className="form-label">
                                Mot de passe <span className="required">*</span>
                            </label>
                            <input
                                type="password"
                                className={`form-input ${
                                    passwordError ? "form-input-error" : ""
                                }`}
                                value={password}
                                onChange={handlePasswordChange}
                            />
                        </div>
                        <button className="btn monitor-button" type="submit">
                            <span>Créer le compte</span>
                            <img src={MonitorButton} alt={MonitorButton} />
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Registration;
